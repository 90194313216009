import { DownOne } from "@icon-park/vue"; //icon图标
import tableNav from '../../components/tableNav.vue' //底部导航栏
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.css';
export default {
    name: 'home',
    components: {
        DownOne,
        tableNav
    },
    data() {
        return {
            selectTableList: [{
                name: '',
                list: [],
                index: 0
            }, {
                name: '类型',
                list: ['周/月票', '次票', '畅玩票']
            }, {
                name: '价格'
            }, ], //下拉选项卡列表
            selectTableIndex: 'none',
            is_show: false,
            tickets: [],
            tickets_class: {
                "次票": [],
                "周/月票": [],
                "畅玩票": []
            },
            tg_list: [],
            tickets_index: '次票',
            classType: 0, //类型 0所有 1周/月票 2次票
            priceF: 0, //价格 0无 1升序 2降序
            act_info: {},
            is_timer: false,
            is_one: true,
            is_one1: true,
            active: 0,
            is_click: false,
            xd_height: 0,
            is_swiper: 0,
            timer_close: true
        }
    },
    created() {
        let _this = this;
        document.title = "哈尔堡";
        this.xd_height = 46.5 * (document.documentElement.clientWidth / 375);
        if (localStorage.getItem('login_data')) {
            let route_data = JSON.parse(localStorage.getItem('login_data'));
            let name = (route_data.name == 'coupon' || route_data.name == 'ptOrderInfo' || route_data.name == 'share') ? route_data.name : 'info/' + route_data.name;
            let query = {
                id: JSON.parse(localStorage.getItem('login_data')).id,
            };
            JSON.parse(localStorage.getItem('login_data')).sn ? query.sn = JSON.parse(localStorage.getItem('login_data')).sn : '';
            JSON.parse(localStorage.getItem('login_data')).single ? query.single = JSON.parse(localStorage.getItem('login_data')).single : '';
            JSON.parse(localStorage.getItem('login_data')).is_pt ? query.is_pt = JSON.parse(localStorage.getItem('login_data')).is_pt : '';
            console.log(name)
            _this.$router.push({
                name: name,
                query: query
            });
        };
        if (this.$route.query.mall_id) {
            localStorage.setItem('mall_id', this.$route.query.mall_id);
        };
        //获取商场列表
        this.getMallList((res) => {
            let list = res.data.data;
            let index = 0;
            // if (!this.$route.query.is_debug) {
            //     list = list.filter(item => item.name !== '测试商场');
            // }
            //获取缓存商场id，根据id获取索引
            for (let i = 0; i < list.length; i++) {
                if (list[i].id == localStorage.getItem('mall_id')) {
                    index = i;
                }
            };
            //获取商场name
            let name = list[index].name;
            //赋值
            this.selectTableList[0] = {
                name: name,
                list: list,
                index: index
            };
            this.$forceUpdate();
            //获取商场门票
            this.getActiveIndex();
        });
    },
    methods: {
        toPf(routerName, data) {
            this.$router.push({
                name: 'info/' + routerName,
                query: {
                    id: data.id,
                    name: this.selectTableList[0].name,
                    is_pt: 1
                }
            });
        },
        handleScroll(event) {
            if (!this.is_click) {
                if (document.querySelector('.one').getBoundingClientRect().y - this.xd_height <= 0) {
                    document.querySelector('.select').classList.add('xd_box');
                } else {
                    document.querySelector('.select').classList.remove('xd_box');
                }
                if (document.querySelector('.one').getBoundingClientRect().y - this.xd_height <= 0) {
                    this.tickets_index = '次票';
                }
                if (document.querySelector('.two').getBoundingClientRect().y - this.xd_height <= 0) {
                    this.tickets_index = '周/月票';
                }
                if (document.querySelector('.three').getBoundingClientRect().y - this.xd_height <= 0) {
                    this.tickets_index = '畅玩票';
                }
            }
        },
        scrollTo(index, target) {
            this.tickets_index = index;
            this.is_click = true;
            switch (target.split('#section')[1]) {
                case '1':
                    document.querySelector('.scroll_box').scrollTop = document.querySelector('.scroll_box').scrollTop + document.querySelector('.one').getBoundingClientRect().top + 1;
                    break;
                case '2':
                    document.querySelector('.scroll_box').scrollTop = document.querySelector('.scroll_box').scrollTop + document.querySelector('.two').getBoundingClientRect().top + 1;
                    break;
                case '3':
                    document.querySelector('.scroll_box').scrollTop = document.querySelector('.scroll_box').scrollTop + document.querySelector('.three').getBoundingClientRect().top + 1;
                    break;
                default:
                    break;
            };
            document.querySelector('.scroll_box').scrollTop = document.querySelector('.scroll_box').scrollTop - this.xd_height + 1;
            setTimeout(() => {
                if (document.querySelector('.one').getBoundingClientRect().y - this.xd_height <= 0) {
                    document.querySelector('.select').classList.add('xd_box');
                } else {
                    document.querySelector('.select').classList.remove('xd_box');
                }
                this.is_click = false;
            }, 10);
        },
        initSwiper() {
            new Swiper('.swiper-container', {
                loop: true,
                autoplay: {
                    delay: 3000, // 自动播放间隔时间，单位为毫秒
                    disableOnInteraction: false // 用户操作后是否停止自动播放，默认为 true
                }
            });
        },
        // 根据时间戳转换日期
        getNewDate(timer) {
            var timestr = new Date(timer)
            var year = timestr.getFullYear();
            var month = timestr.getMonth() + 1;
            var date = timestr.getDate();
            return year + "." + (month < 10 ? '0' + month : month) + "." + (date < 10 ? '0' + date : date);
        },
        //获取商场列表
        getMallList(fn) {
            this.$UtilsApi.mallList({}).then(res => {
                if (res.data.code == 0) {
                    fn(res)
                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        //获取商场门票
        getActiveIndex() {
            this.$UtilsApi.activeIndex({
                mall_id: localStorage.getItem('mall_id')
            }).then(res => {
                if (res.data.code == 0) {
                    if (new Date().getTime() < this.act_info.end_time * 1000) {
                        this.timer_close = false;
                    }
                    //获取数据
                    this.act_info = res.data.data.act_info;
                    if (this.act_info.banner.length) {
                        localStorage.setItem('fx_url', this.act_info.banner[0]);
                    };
                    if (this.act_info.banner.length < 3) {
                        for (let i = 0; i < 3 - this.act_info.banner.length; i++) {
                            this.act_info.banner.push(this.act_info.banner[0]);
                        }
                    };
                    this.act_info.banner.unshift(this.act_info.banner.pop());
                    console.log(this.act_info.banner)
                    localStorage.setItem('fx_name', this.act_info.mall_name);
                    let data = res.data.data.tickets;
                    //加载轮播图
                    if (this.is_one1) {
                        setTimeout(() => {
                            this.initSwiper();
                        }, 10);
                        this.is_one1 = false;
                    }
                    if (this.is_one && (res.data.data.act_info.end_time * 1000) - (8 * 86400000) < new Date().getTime()) {
                        this.is_timer = true;
                        this.is_one = false;
                    }
                    this.tickets = data;
                    data.forEach(item => {
                        if (item.group_cnf) {
                            this.tg_list.push(item)
                        } else {
                            this.tickets_class[item.type == 1 ? '次票' : item.type == 2 ? '周/月票' : '畅玩票'].push(item);
                        }
                    });
                    //设置滚动区域底部高度
                    this.$nextTick(() => {
                        document.querySelector('.four').style.height = (document.querySelector('.scroll_box').getBoundingClientRect().height - document.querySelector('.three').getBoundingClientRect().height - document.querySelector('.select').getBoundingClientRect().height) * (document.documentElement.clientWidth / 375) + 'rem'
                    });
                    console.log(this.tickets_class);

                } else {
                    this.$notify({ type: 'warning', message: res.data.msg, duration: 2000 });
                }
            });
        },
        //数组对象排序
        compare(prop) {
            return function(obj1, obj2) {
                var val1 = obj1[prop];
                var val2 = obj2[prop];
                if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
                    val1 = Number(val1);
                    val2 = Number(val2);
                }
                if (val1 < val2) {
                    return -1;
                } else if (val1 > val2) {
                    return 1;
                } else {
                    return 0;
                }
            }
        },
        //选择类型
        SelectType(item, index) {
            let arr = ['类型', '周/月票', '次票', '畅玩票']
            this.classType = this.classType == index + 1 ? 0 : index + 1;
            this.selectTableIndex = 'none';
            console.log(this.classType)
            this.selectTableList[1].name = arr[this.classType];
            //获取商场门票
            this.getActiveIndex();
        },
        //选择商场
        SelectMall(item, index) {
            //修改缓存商场id
            localStorage.setItem('mall_id', item.id);
            this.selectTableList[0].index = index;
            this.selectTableList[0].name = item.name;
            this.selectTableIndex = 'none';
            const newUrl = this.$route.query.is_debug ? `#/home?mall_id=${item.id}` + '&is_debug=1' : `#/home?mall_id=${item.id}`
            window.history.replaceState('', '', newUrl)

            this.$forceUpdate();
            //获取商场门票
            this.getActiveIndex();
        },
        //显示选项
        showOptions(index) {
            if (index == 0) {
                return
            }
            this.selectTableIndex = this.selectTableIndex == index ? 'none' : index == 2 ? 'hide' : index;
            if (index == 2) {
                if (this.priceF == 0 || this.priceF == 2) {
                    this.priceF = 1;
                } else {
                    this.priceF = 2;
                }
                //获取商场门票
                this.getActiveIndex();
            }
        },
        onConfirm() {
            console.log('确定')
        },
        //去详情
        toInfo(routerName, data) {
            this.$router.push({
                name: 'info/' + routerName,
                query: {
                    id: data.id,
                    name: this.selectTableList[0].name,
                }
            });
        },
        //关闭弹窗
        closeTimer() {
            if (new Date().getTime() < this.act_info.end_time * 1000) {
                this.is_timer = false;
            }
        }
    },
}